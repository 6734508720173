$(function() {
 // 以下為Swiper設定

    // 共用Auto 
    var auplayFun = {
      delay: 3500,
      disableOnInteraction: false,
    }

    // 進場動畫
    var pageFun = function(){
      var percent = 0;
      var timer = setInterval(function () {
        $(".bar").css("width", percent + "%");
        percent += 1;

        if (percent > 100) {
          clearInterval(timer);
          
          // 載完loading就把className拿掉...
          $('.js-loadHide').removeClass('js-loadHide');
          $(".pageloading").addClass("compelet");

          setTimeout(function () {
            bannerSwiper.params.autoplay = auplayFun;
            bannerSwiper.autoplay.start();
          },0);
        }
      }, 0);
    }

    if($('.bannerSwiper').length > 0) {
      let ElTranslateFlag = true;

      // 首頁Banner
      var bannerSwiper = new Swiper('.bannerSwiper .swiper-container', {
        autoplay: auplayFun,
        parallax: true,
        speed: 2000,
  
        pagination: {
          el: '.bannerSwiper .swiper-pagination',
          clickable: true,
        },
      });

      // bannerSwiper.autoplay.stop();
      // pageFun();
    }

    // js using jquery
    setInterval(function(){
      $('.bannerSwiper .swiper-slide .txtInfo .title, .bannerSwiper .swiper-slide .txtInfo .txt,  .bannerSwiper .swiper-slide .imgInfo, .bannerSwiper .swiper-slide .imgStyle').addClass('animate__animated');

      $('.bannerSwiper .swiper-slide-active .txtInfo .title').addClass('animate__fadeInDown animate__slow');
      $('.bannerSwiper .swiper-slide-active .txtInfo .txt').addClass('animate__fadeInUp animate__slow'); 
      $('.bannerSwiper .swiper-slide-active .imgStyle').addClass('animate__backInUp animate__slower');
      $('.bannerSwiper .swiper-slide-active .imgInfo').addClass('animate__fadeInRight'); 

      $(".bannerSwiper .swiper-slide-next .txtInfo .title, .swiper-slide-prev .txtInfo .title").removeClass('animate__animated, animate__fadeInDown');
      $(".bannerSwiper .swiper-slide-next .txtInfo .txt, .swiper-slide-prev .txtInfo .txt").removeClass('animate__animated, animate__fadeInUp');
      $(".bannerSwiper .swiper-slide-next .imgStyle, .swiper-slide-prev .imgStyle").removeClass('animate__animated, animate__backInUp');
      $(".bannerSwiper .swiper-slide-next .imgInfo, .swiper-slide-prev .imgInfo").removeClass('animate__animated, animate__fadeInRight');
    });

    // 首頁最新消息
    var indexNewsSwiper = new Swiper(".indexNews-swiper .mySwiper", {
      slidesPerView: 3,
      spaceBetween: 5,
      grabCursor: true,
      navigation: {
        nextEl: ".indexNews-swiper .swiper-button-next",
        prevEl: ".indexNews-swiper .swiper-button-prev",
      },
      breakpoints: { 
        320: {  //当屏幕宽度大于等于320
          slidesPerView: 1,
          spaceBetween: 0
        },
        768: {  //当屏幕宽度大于等于768 
          slidesPerView: 3,
          spaceBetween: 5,
        },
        1020: {  //当屏幕宽度大于等于1020 
          slidesPerView: 2,
          spaceBetween: 5,
        },
        1280: {  //当屏幕宽度大于等于1280
          slidesPerView: 3,
          spaceBetween: 5,
        }
      },
    });

    // 首頁活動
    var indexActSwiper = new Swiper(".indexAct-swiper .mySwiper", {
      slidesPerView: 3,
      spaceBetween: 5,
      grabCursor: true,
      navigation: {
        nextEl: ".indexAct-swiper .swiper-button-next",
        prevEl: ".indexAct-swiper .swiper-button-prev",
      },
      breakpoints: { 
        320: {  //当屏幕宽度大于等于320
          slidesPerView: 1,
          spaceBetween: 0
        },
        768: {  //当屏幕宽度大于等于768 
          slidesPerView: 3,
          spaceBetween: 5,
        },
        1020: {  //当屏幕宽度大于等于1020 
          slidesPerView: 2,
          spaceBetween: 5,
        },
        1280: {  //当屏幕宽度大于等于1280
          slidesPerView: 3,
          spaceBetween: 5,
        }
      },
    });

    // 活動照片
    var actSwiper = new Swiper(".newsAct-photo .mySwiper", {
      // autoplay: auplayFun,
      autoplay:false,
      slidesPerView: 5,
      spaceBetween: 20,
      freeMode: true,
      watchSlidesProgress: true,
      breakpoints: { 
        320: {  //当屏幕宽度大于等于320
          slidesPerView: 3,
          spaceBetween: 5,
        },
        768: {  //当屏幕宽度大于等于768 
          slidesPerView: 3,
          spaceBetween: 10
        },
        1280: {  //当屏幕宽度大于等于1280
          slidesPerView: 5,
          spaceBetween: 20
        }
      }
    });

    var actSwiper2 = new Swiper(".newsAct-photo .mySwiper2", {
      // autoplay: auplayFun,
      autoplay:false,
      speed: 1000,
      spaceBetween: 10,
      navigation: {
        nextEl: ".newsAct-photo .swiper-button-next",
        prevEl: ".newsAct-photo .swiper-button-prev",
      },
      thumbs: {
        swiper: actSwiper,
      },
    });


// 以下為Gotop
  $(".float-link .link-btn.top, .float-link .link-btn.mobile-top").on('click',function () {
    $("html,body").animate({ "scrollTop": "0" })
  });

  $(window).on("load resize scroll", function (e) {
    var scroll = $(this).scrollTop();
    var wdh = $(window).height();
    if (scroll > 50) {
        $(".float-link").addClass("active");
    } else {
        $(".float-link").removeClass("active");
    }
  });

  //// ---- 以下為call Fun ---- ////
  scrollLink('.scrollLink' )

});

// 以下為點擊後滾動至該區塊 : '.scrollLink'  
function scrollLink($name){
  $($name).on('click', function(e) {
      e.preventDefault();
      var target = $(this).attr("href");
      // 抓取置頂的header 高度 / 讓滾動的時候在減去此高度
      var h = $('.start-style').innerHeight() + 30;

      $('html, body').stop().animate({ 
        scrollTop: $(target).offset().top - h
      }, 1000);
      
      return false;
  });
}
